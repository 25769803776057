// @ts-nocheck
const resource = {
  "form.hint.optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optionnel"])},
  "form.placeholder.select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner"])},
  "form.validation.email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce champ doit être une adresse électronique."])},
  "form.validation.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce champ est obligatoire."])},
  "mail.layout.closing:hangcha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Team Hangcha"])},
  "mail.layout.footer:hangcha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hangcha - xxx 123, 6666 xxxx, Belgium"])},
  "mail.layout.greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonjour,"])},
  "page.title.home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["test"])}
}
export default resource