import en from "../locales/generated/en";
import fr from "../locales/generated/fr";
import nl from "../locales/generated/nl";
import { defaultLocale } from "./canonicalSettings";

export default defineI18nConfig(() => ({
  legacy: false,
  locale: defaultLocale,
  fallbackLocale: defaultLocale,
  fallbackWarn: false,
  missingWarn: false,
  messages: {
    nl: nl,
    fr: fr,
    en: en,
  },
}));
