// @ts-nocheck
const resource = {
  "form.hint.optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optional"])},
  "form.placeholder.select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
  "form.validation.email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field should be an e-mail address."])},
  "form.validation.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is mandatory."])},
  "mail.layout.closing:hangcha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Team Hangcha"])},
  "mail.layout.footer:hangcha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hangcha - xxx 123, 6666 xxxx, Belgium"])},
  "mail.layout.greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hello,"])},
  "page.title.home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["test"])}
}
export default resource